<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="year" type="year" placeholder="选择年份" value-format="yyyy"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="getList()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                        <el-row :gutter="20" v-if="teamPerformanceInfo.isTeamPerformanceReward">
                            <el-col :span="24">
                                <el-descriptions :title="`绩效周期：${performanceRewardCycleName}`" direction="vertical"
                                    :column="teamPerformanceInfo.performanceRewardScale.length" border>
                                    <el-descriptions-item :label="`${item.startValue}-${item.endValue}`"
                                        v-for="(item, index) in teamPerformanceInfo.performanceRewardScale" :key="index">
                                        {{ item.scopeRatio }}%</el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 187px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="cycleNum" label="周期" width="100" align="center">
                    </el-table-column>
                    <el-table-column prop="cycleStartTime" label="开始时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.cycleStartTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cycleEndTime" label="结束时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.cycleEndTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalConsumeAmount" label="交易金额(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="rewardAmount" label="绩效奖励(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="teamMembers" label="团队人数" align="center">
                    </el-table-column>
                    <el-table-column prop="rewardAmount" label="是否已发放奖励" align="center">
                        <template slot-scope="scope">
                            <el-tag size="small" v-if="scope.row.isGrant" type="success">是</el-tag>
                            <el-tag size="small" v-else type="warning">{{ compareDate(scope.row.cycleEndTime) ? '否' :
                                '周期未结束'
                            }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="发放时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.grantTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="220" align="center">
                        <template slot-scope="scope">
                            <span class="optionBtn" :class="scope.row.rewardAmount == 0 ? 'primaryDis' : 'primary'"
                                @click="toTeamPerformance(scope.row)"
                                v-if="!scope.row.isGrant && compareDate(scope.row.cycleEndTime)">发放绩效
                            </span>
                            <span class="optionBtn" :class="scope.row.rewardAmount == 0 ? 'primaryDis' : 'primary'"
                                @click="toDetail(scope.row)" v-if="scope.row.isGrant">绩效详情</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="发放绩效" :visible.sync="scaleDialog" class="dialog">
            <el-table :data="tableData2" stripe height="500px" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column prop="userName" label="会员名称">
                </el-table-column>
                <el-table-column prop="childrenTotal" label="下级会员数" align="center"> </el-table-column>
                <el-table-column prop="teamConsumeAmount" label="本人+下级消费金额" align="center"> </el-table-column>
                <el-table-column prop="userConsumeAmount" label="本人消费金额" align="center">
                </el-table-column>
                <el-table-column prop="teamPercentageTotal" label="本人+下级绩效金额" align="center">
                </el-table-column>
                <el-table-column prop="ratio" label="绩效比例" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.ratio }}%</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userChildrenPercentageTotal" label="下级获得绩效金额" align="center">
                </el-table-column>
                <el-table-column prop="userPercentageTotal" label="本人获得绩效金额" align="center">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="scaleDialog = false">取 消</el-button>
                <el-button type="primary" @click="toGrant()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { TeamPerformance } from "../../../components/HospitalDomain/TeamPerformance";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var temPerformance = new TeamPerformance(this.TokenClient, this.Services.Authorization);
        return {
            TeamPerformanceDomain: temPerformance,
            tableData: [],
            tableData2: [],
            commanderId: this.$route.query.commanderId,
            year: new Date().getFullYear().toString(),
            pickerOptions: {
                //disabled为函数，返回值为布尔值，
                disabledDate: (time) => {
                    let minYear = new Date().getFullYear() - 30
                    return time > Date.now() || time.getTime() < new Date(JSON.stringify(minYear))
                }
            },
            formLabelWidth: '150px',
            scaleDialog: false,
            teamPerformanceInfo: {
                isTeamPerformanceReward: false,
                performanceRewardCycle: 1,
                performanceRewardScale: []
            },
            performanceRewardCycleName: '',
            cycleId: ''
        }
    },
    mounted() {
        this.getTeamPerformanceInfo();
        this.getList();
    },
    methods: {
        compareDate(endTime) {
            var date = new Date();
            //2把字符串格式转换为日期类
            var startTime = new Date(Date.parse(date));
            var endTime = new Date(Date.parse(endTime));

            //进行比较
            return (startTime > endTime);
        },
        /**
         * 绩效发放计算
         * @param {*} item 
         */
        toTeamPerformance(item) {
            var _this = this
            this.scaleDialog = true
            this.cycleId = item.id
            _this.TeamPerformanceDomain.GetTeamPerformanceCalculation(_this.commanderId, _this.cycleId,
                function (data) {
                    // 树形结构转扁平
                    const flatTree = (data, childName = 'children') => {
                        if (!Array.isArray(data)) {
                            console.warn('只支持传入数组')
                            return []
                        }
                        return data.reduce((prev, curt) => {
                            // 有子节点的话把子节点作为一级节点递归遍历
                            const childs = curt[childName]?.length ? flatTree(curt[childName]) : []
                            return [...prev, curt, ...childs]
                        }, [])
                    }
                    _this.tableData2 = flatTree(data.data)

                },
                function (error) {
                    console.log(error);
                });
        },
        /**
         * 绩效发放
         */
        toGrant() {
            var _this = this
            _this.TeamPerformanceDomain.GetTeamPerformanceGrant(_this.commanderId, _this.cycleId,
                function (data) {
                    _this.scaleDialog = false
                    _this.getList();
                },
                function (error) {
                    console.log(error);
                });
        },
        toDetail(item) {
            this.$router.push({
                path: '/Finance/CommandeTeamPerformanceDetail',
                query: {
                    commanderId: item.commanderId,
                    cycleId: item.id,
                },
            })
        },
        getList() {
            var _this = this;
            _this.tableData = []
            _this.TeamPerformanceDomain.GetTeamPerformanceById(_this.commanderId, _this.year,
                function (data) {
                    _this.tableData = data.data;
                },
                function (error) {
                    console.log(error);
                });
        },
        /**
         * 获取绩效设置
         */
        getTeamPerformanceInfo() {
            var _this = this;
            _this.TeamPerformanceDomain.GetTeamPerformanceInfo(new Date().getFullYear(), function (data) {
                _this.teamPerformanceInfo = data.data;
                if (!_this.teamPerformanceInfo.isTeamPerformanceReward) {
                    _this.$message({
                        type: 'warning',
                        message: '该店铺未开启团队绩效奖励'
                    });
                }
                _this.performanceRewardCycleName = '按月统计'
                if (_this.teamPerformanceInfo.performanceRewardCycle == 2) {
                    _this.performanceRewardCycleName = '按季统计'
                }
                if (_this.teamPerformanceInfo.performanceRewardCycle == 3) {
                    _this.performanceRewardCycleName = '按年统计'
                }
            }, function (err) {
                console.log(err);
                if (err.status == 400) {
                    _this.$message({
                        type: 'error',
                        message: '请前往设置团队绩效比例'
                    });
                }
            });


        },
    }
}
</script>

<style scoped>
.el-input {
    width: 95%;
}

.table-top {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-form-item {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-descriptions__header {
    margin-bottom: 10px;
}
</style>
